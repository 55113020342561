<template>
    <div>        
        <b-form>
            <b-row >
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Código de Proyecto" label-for="basicInput">                        
                        <b-form-input v-model = "projectCode" placeholder="Digite el código de Proyecto"></b-form-input>
                    </b-form-group>                    
                </b-col>
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Nombre NeoGanadero" label-for="basicInput">                        
                        <b-form-input v-model = "investorName" placeholder="Digite el Nombre de Neoganadero"></b-form-input>
                    </b-form-group>                    
                </b-col> 
                <!-- <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Nombre Proyecto" label-for="basicInput">                        
                        <b-form-input v-model = "projectName" placeholder="Digite el Nombre de Proyecto"></b-form-input>
                    </b-form-group>                    
                </b-col> -->
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Email Neoganadero" label-for="basicInput">                        
                        <b-form-input v-model = "investorEmail" placeholder="Digite el Email del Neoganadero"></b-form-input>
                    </b-form-group>                    
                </b-col>
                <b-col md="2" xl="2" class="mb-1"> 
                    <b-form-group label="Estado Proyecto" label-for="basicInput">                        
                        <b-form-select v-model = "projectStatus" :options="projectStatusOptions"></b-form-select>
                    </b-form-group>                    
                </b-col>
                <b-col md="2" xl="2" class="mt-2">                          
                    <b-button  variant="primary" @click="investmentsToApprove" >
                        <feather-icon icon="SearchIcon" class="mr-50"/>Filtrar
                    </b-button>
                </b-col>    
                <b-col md="2" xl="2" class="mt-2 text-right">                          
                    <b-button  variant="warning" @click="showModalManualInvestment">
                        <feather-icon icon="PlusIcon" class="mr-50"/>Crear Inversion
                    </b-button>                        
                </b-col>                                     
            </b-row>
        </b-form>   

        <b-card-code title="Inversiones" no-body>
            <b-table
            ref="selectableTable"
            :fields="fields"
            :items="investments" 
            responsive
            selectable
            class="mb-0"
            :select-mode="selectMode"
            @row-selected="onRowSelected"                        
            >
                <template #cell(amountInvested)="data">
                    <p style="margin:0px"><b>Invertido: </b>$ {{parseFloat(data.item.amountInvested).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</p>
                    <p v-if="data.item.amountReceived" style="margin:0px"><b>Recibido: </b>$ {{parseFloat(data.item.amountReceived).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}}</p>
                    <b-badge v-if="data.item.isGift" pill variant="warning" style="font-size:12px" >Es regalo</b-badge>
                </template>
                <template #cell(totalKilograms)="data">
                    <p style="margin:0px" v-if="data.item.projectType == 'Levante' || data.item.projectType == 'Ceba'">{{parseFloat(data.item.totalKilograms).toFixed(2)}} Kg</p> 
                    <p style="margin:0px" v-if="data.item.projectType == 'Cria'">{{parseFloat(data.item.totalKilograms).toFixed(2)}} Sty</p>
                </template>                
                <template #cell(truoraCheckScore)="data">  
                    <p style="margin:0px" v-if="data.item.truoraCheckScore">{{((parseFloat(String(data.item.truoraCheckScore).toString().replace(',', '.')).toFixed(2))) }}</p>
                    <p style="margin:0px" v-else> </p>                    
                </template> 
                <template #cell(investorName)="data">
                    <p style="margin:0px">{{ data.item.investorName}}</p>
                    <b-badge v-if="data.item.observations != null" pill variant="success" style="font-size:12px" >{{ data.item.observations }}</b-badge>
                </template>

                <template #cell(projectCode)="data">
                    <p style="margin:0px">{{ data.item.projectCode}}</p>
                    <b-badge v-if="data.item.projectStatus == 'Finalizado'" pill variant="danger" style="font-size:12px" >{{ data.item.projectStatus }}</b-badge>
                    <b-badge v-if="data.item.projectStatus == 'Ejecucion'" pill variant="success" style="font-size:12px" >{{ data.item.projectStatus }}</b-badge>
                    <b-badge v-if="data.item.projectStatus == 'Nuevo'" pill variant="info" style="font-size:12px" >{{ data.item.projectStatus }}</b-badge>
                    <b-badge v-if="data.item.projectStatus == 'Recaudo'" pill variant="success" style="font-size:12px" >{{ data.item.projectStatus }}</b-badge>
                    <b-badge v-if="data.item.projectStatus == 'Pendiente'" pill variant="info" style="font-size:12px" >{{ data.item.projectStatus }}</b-badge>
                </template>
            </b-table>

        </b-card-code>

        <b-row v-if="investments.length <= 0" >
            <b-col xl="4" md="6" lg="4" offset-lg="4" offset-md="3" offset-xl="4"  >
                <b-alert variant="primary" show>
                    <div class="alert-body">
                        <span><strong>No Hay Proyectos para mostrar</strong></span>
                    </div>
                </b-alert>
            </b-col>
        </b-row>

        <b-modal centered ref="my-modal" hide-footer title="Que deseas hacer?">          
            <b-button class="mt-1" variant="outline-secondary" block @click="downloadPaymentProof(selectedInvestment.paymentProofUrl)" v-if="!showUpdateInvestmentAmount" >
                <feather-icon icon="ArrowDownCircleIcon" class="mr-50"/>
                <span class="align-middle">Descargar comprobante de Pago</span>
            </b-button>
            <b-button class="mt-1" variant="outline-secondary" block @click="downloadTruoraPdf" v-if="!showUpdateInvestmentAmount" >
                <feather-icon icon="ArrowDownCircleIcon" class="mr-50"/>
                <span class="align-middle">Descargar Truora PDF</span>
            </b-button>
            <b-button class="mt-1" variant="outline-primary" block @click="viewProjectPublicDetails" v-if="!showUpdateInvestmentAmount">
                <feather-icon icon="EyeIcon" class="mr-50"/>
                <span class="align-middle">Ver Proyecto</span>
            </b-button>
            <b-button class="mt-1" variant="outline-primary" block @click="editInvestment" v-if="!showUpdateInvestmentAmount">
                <feather-icon icon="EditIcon" class="mr-50"/>
                <span class="align-middle">Editar Inversión</span>
            </b-button>
            <b-button class="mt-1" variant="outline-primary" block @click="viewInvestorDetails" v-if="!showUpdateInvestmentAmount">
                <feather-icon icon="EyeIcon" class="mr-50"/>
                <span class="align-middle">Detalles NeoGanadero</span>
            </b-button>
            <p v-if="showUpdateInvestmentAmount">Inversion actual: ${{selectedInvestment.amountInvested.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP</p> 
            <b-form-group label="Nuevo Valor" label-for="basicInput" v-if="showUpdateInvestmentAmount">
                <b-form-input v-model.number="investmentNewValue" placeholder="Digite Nuevo Valor"/>
            </b-form-group>
            <b-button class="mt-1" variant="warning" block @click="showUpdateInvestmentAmount = false" v-if="showUpdateInvestmentAmount">
                <feather-icon icon="ArrowLeftIcon" class="mr-50"/>
                <span class="align-middle">Volver</span>
            </b-button>     
            <b-button class="mt-1" variant="primary" block @click="updateInvestmentAmount" v-if="showUpdateInvestmentAmount">
                <feather-icon icon="CheckSquareIcon" class="mr-50"/>
                <span class="align-middle">Actualizar</span>
            </b-button>    
            <b-button class="mt-1" variant="primary" block @click="approveInvestment" v-if="!showUpdateInvestmentAmount">
                <feather-icon icon="CheckSquareIcon" class="mr-50"/>
                <span class="align-middle">Aprobar Inversion</span>
            </b-button>

            <b-button class="mt-1" variant="warning" block @click="deleteInvestment" v-if="!showUpdateInvestmentAmount">
                <feather-icon icon="DeleteIcon" class="mr-50"/>
                <span class="align-middle">Borrar Inversion</span>
            </b-button>

            <b-button class="mt-1" variant="success" block @click="showAssociateInvestmentModal"  v-if="!showUpdateInvestmentAmount">
                <feather-icon icon="RepeatIcon" class="mr-50"/>
                <span class="align-middle">Asociar Factura SIIGO</span>
            </b-button>

            <b-button class="mt-1" variant="success" block @click="generateInvoice(false)" v-if="!showUpdateInvestmentAmount">
                <feather-icon icon="RepeatIcon" class="mr-50"/>
                <span class="align-middle">Generar Factura SIIGO</span>
            </b-button>

            <b-button class="mt-1" variant="danger" block @click="generateInvoice(true)" v-if="!showUpdateInvestmentAmount && selectedInvestment.siigoInvoiceName == 'Error'">
                <feather-icon icon="RepeatIcon" class="mr-50"/>
                <span class="align-middle">Reintentar Facturar</span>
            </b-button>

            <b-button class="mt-1" variant="outline-warning" block @click="toogleGenerateSiigoInvoice" v-if="!showUpdateInvestmentAmount">
                <feather-icon icon="DeleteIcon" class="mr-50"/>
                <span class="align-middle">Marcar/Desmarcar para no facturar</span>
            </b-button>
        </b-modal>

        <b-modal centered ref="manual-investment-modal" hide-footer title="Digíta la Siguiente Información">          
            <b-row>
                <b-col class="mb-1">
                    <b-form-group label="Código del Proyecto" label-for="basicInput">
                        <b-form-input v-model="manualInvestment.projectCode"/>
                    </b-form-group>
                </b-col>
            </b-row>
             <b-row>
                <b-col class="mb-1">
                    <b-form-group label="Email del Inversionista" label-for="basicInput">
                        <b-form-input v-model="manualInvestment.investorEmail"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col class="mb-1">
                    <b-form-group label="Cantidad a Invertir" label-for="basicInput">
                        <b-form-input v-model.number="manualInvestment.amountToInvest"/>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" xl="6" class="mb-2">
                    <b-form-checkbox v-model="manualInvestment.isReinvestment" checked="false" name="check-button" switch inline>Usar Saldo</b-form-checkbox>
                </b-col> 
            </b-row>

            <b-row>
                <b-col class="mb-1">
                    <b-form-group label="Observaciones" label-for="basicInput">
                        <b-form-input v-model="manualInvestment.observations"/>
                    </b-form-group>
                </b-col>
            </b-row>



            <b-button class="mt-1" variant="primary" block @click="createManualInvestment">
                <feather-icon icon="PlusIcon" class="mr-50"/>
                <span class="align-middle">Crear Inversion</span>
            </b-button>
        </b-modal>

        <b-modal centered ref="asociate-investment" hide-footer title="Asociar Factura SIIGO con Inversion">          
            <b-row>
                <b-col class="mb-1">
                    <b-form-group label="Factura de SIIGO" label-for="basicInput">
                        <b-form-input v-model="manualSiigoInvoiceName"/>
                    </b-form-group>
                </b-col>
            </b-row>

            <b-button class="mt-1" variant="primary" block @click="syncInvoiceWithInvestment">
                <feather-icon icon="PlusIcon" class="mr-50"/>
                <span class="align-middle">Asociar</span>
            </b-button>
        </b-modal>
    </div>    
</template>

<script>
import axios from '@axios'
import BCardCode from '@/@core/components/b-card-code/BCardCode.vue';
import { BAlert, BTable, BForm, BFormInput, BBadge, BFormGroup, BFormSelect, BButton, BRow, BCol, BFormCheckbox} from 'bootstrap-vue'

export default {
        components: {
        BCardCode,
        BBadge,
        BTable,
        BForm,
        BRow,
        BCol,
        BFormGroup,
        BFormInput,
        BButton,
        BFormSelect,
        BAlert,
        BFormCheckbox
    },
        data(){
        return{
            selectMode: 'single',

            fields: [
                { key: 'projectCode', label: 'Código Proyecto' },
                { key: 'amountInvested', label: 'Monto', thStyle:{ width: "15%"}},
                { key: 'totalKilograms', label: 'Participación' },
                { key: 'investorName', label: 'Nombre NeoGanadero' },                
                { key: 'truoraCheckScore', label: 'Truora' },
                { key: 'investorEmail', label: 'Email NeoGanadero' },                         
                //{ key: 'investorTelephone', label: 'Telefono NeoGanadero' }, 
                { key: 'createDate', label: 'Fecha de Inversion' },
                { key: 'isConfirmed', label: 'Confirmada' },  
                { key: 'epaycoTransactionRef', label: 'Epayco Ref' }, 
                { key: 'siigoInvoiceName', label: 'Factura Siigo' }, 
            ],
            projectStatusOptions:[
                { value: '', text: 'Todos' },
                { value: 'Recaudo', text: 'Recaudo' },
                { value: 'Nuevo', text: 'Nuevo' },
                { value: 'Pendiente', text: 'Pendiente' },    
                { value: 'Ejecucion', text: 'Ejecucion' },
                { value: 'Finalizado', text: 'Finalizado' },
            ],
            investments: [{ }],
            selectedInvestment: {},
            investorName:'',
            projectName:'',
            producerName:'',
            investorEmail:'',
            projectCode:'',
            projectStatus:'',
            investmentNewValue: 0,
            showUpdateInvestmentAmount: false,
            showUpdateInvestmentAmount: false,  
            manualInvestment: {},   
            manualSiigoInvoiceName: '', 
            forced: null,         
        }
    },
    created(){ 
        this.projectStatus = 'Recaudo' 
        this.investmentsToApprove();  
    },
    methods:{
        investmentsToApprove(){                    
            axios.get('/Investment/GetInvestementsToApprove',{params: {investor: this.investorName, project: this.projectName, 
            investorEmail: this.investorEmail, code: this.projectCode, status: this.projectStatus}})
            .then(response => {                
                this.investments = response.data;
            })
        },
        onRowSelected(items) {    
            this.selectedInvestment = items[0];  
            this.investmentNewValue = 0;  
            this.investmendID = this.selectedInvestment.investmendID          
            if(this.selectedInvestment) this.showModal();
        },
        showModal() {
            this.$refs['my-modal'].show()
        },
        showModalManualInvestment() {
            this.$refs['manual-investment-modal'].show()
        },
        approveInvestment(){            
            axios.get('/Investment/ApproveInvestment', { params: { investmentdID: this.selectedInvestment.investmendID, projectID: this.selectedInvestment.projectID }})
            .then(response => {     
                axios.get('/User/UpdateActiveCampaignDeal', { params: { userID: this.selectedInvestment.investorID, status: 1, stage: '6' } })           
                this.investmentsToApprove();
                this.$refs['my-modal'].hide();                
            })            
        },
        downloadPaymentProof(fileUrl){                        
            window.open(fileUrl);         
        },
        hideModal() {
            this.$refs['my-modal'].hide()
        },
        viewProjectPublicDetails() {
            let route = this.$router.resolve({path: 'public-project-details?projectCode=' + this.selectedInvestment.projectCode});
            window.open(route.href, '_blank');
        },
        viewInvestorDetails(){    
            let route = this.$router.resolve({path: 'edit-investor-profile?investorID=' + this.selectedInvestment.investorID});
            window.open(route.href, '_blank');
        },
        updateInvestmentAmount(){  
            axios.get('/Investment/updateInvestmentAmount', { params: { investmentID: this.selectedInvestment.investmendID, newValue: this.investmentNewValue }})
            .then(response => {   
                this.showUpdateInvestmentAmount=false;             
                this.investmentsToApprove();
                this.$refs['my-modal'].hide();                  
            })  
        },
        downloadTruoraPdf(){    
            axios.get('/Investment/DownloadTruoraPDF', { params: { investorID: this.selectedInvestment.investorID }})
            .then(response => {       
                window.open(response.data, '_blank');
            })
        },
        editInvestment() {
            let route = this.$router.resolve({path: 'edit-investment?investmendID=' + this.selectedInvestment.investmendID});
            window.open(route.href, '_blank');
        },

        deleteInvestment() {
            axios.get('/Investment/DeleteInvestment', { params: { investmentdID: this.selectedInvestment.investmendID}})
            .then(response => {
                this.$bvToast.toast("La inversión ha sido borrada", { title: 'Ok', variant: 'success', solid: true})   
                this.investmentsToApprove();
                this.$refs['my-modal'].hide();    
            })
        },

        createManualInvestment() {
            axios.post('/Investment/CreateManualInvestment', this.manualInvestment)
            .then(response => {
                this.$bvToast.toast("La inversión ha sido Creada", { title: 'Ok', variant: 'success', solid: true})   
                this.investmentsToApprove();
                this.$refs['my-modal'].hide();    
            }).catch(error => {
                if(error.response){
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        showAssociateInvestmentModal() {
            this.$refs['my-modal'].hide();
            this.$refs['asociate-investment'].show()
        },
        syncInvoiceWithInvestment(){
            axios.get('/Investment/syncInvoiceWithInvestment', { params: { investmentID: this.selectedInvestment.investmendID, invoiceName: this.manualSiigoInvoiceName}})
            .then(response => {
                this.$bvToast.toast("La inversión ha sido asociada", { title: 'Ok', variant: 'success', solid: true})   
                this.investmentsToApprove();
                this.$refs['my-modal'].hide();  
            }).catch(error => {                
                this.$refs['asociate-investment'].hide()
                if(error.response){
                    this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
                } else {
                    this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
                }
            })
        },
        generateInvoice(forced){
            axios.get('/Investment/GenerateInvoice', { params: { investmentID: this.selectedInvestment.investmendID, forced: forced }})
            .then(response => {
                this.$bvToast.toast("La factura ha sido emitida", { title: 'Ok', variant: 'success', solid: true})   
                this.investmentsToApprove(); 
                this.$refs['my-modal'].hide();
            })
        },
        toogleGenerateSiigoInvoice(){
            axios.get('/Investment/ToogleGenerateSiigoInvoice', { params: { investmentID: this.selectedInvestment.investmendID }})
            .then(response => {
                this.$bvToast.toast("OK", { title: 'Ok', variant: 'success', solid: true})   
                this.investmentsToApprove();
                this.$refs['my-modal'].hide();  
            })
        },
    }
}
    
        
</script>